























































































































































































































































$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.login-loading {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.logging-in-message {
  margin-bottom: 1rem;
  text-align: center;
}

.text-center {
  text-align: center;
}

.sign-up-link {
  color: #ffffff;
  font-family: "Montserrat" !important;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.google-btn.p-button.p-component {
  border-radius: 49px;
  box-shadow: 0 3px 20px 0 rgba(99, 15, 245, 0.3);
  background: linear-gradient(186deg, #4353ed 20%, #6473f0 78%);
  margin-top: 1.9rem;
  margin-bottom: 1rem;
  padding: 1rem 7rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat" !important;
  color: white;
  &:hover {
    box-shadow: 0 0 6px $google-blue;
    background: linear-gradient(186deg, #4353ed 30%, #6473f0 70%);
  }
  &:active {
    background: $button-active-blue;
  }
}

#signin-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2d3142;
  color: #ffffff;

  #loading-logo-block {
    position: absolute;
    bottom: 0;

    div span {
      color: #ffffff;
    }
  }

  #loading-text {
    span {
      color: #ffffff;
    }

    svg {
      g,
      path {
        stroke: #ffffff;
      }
    }
  }
}

#login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.version {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-duration: 20s;

  &.top {
    top: -294px;
    -webkit-animation-name: top;
  }

  &.bottom {
    bottom: -294px;
    -webkit-animation-name: bottom;
  }
}

@-webkit-keyframes top {
  0% {
    left: -65%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -65%;
  }
}

@-webkit-keyframes bottom {
  0% {
    right: -65%;
  }
  50% {
    right: 100%;
  }
  100% {
    right: -65%;
  }
}
